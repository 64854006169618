
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class CorrectAnswerModal extends Vue {

    @Prop() totalMilliseconds: number;

    @Prop({
        default: () => null
    }) tooltipLocalized: OM.LocalizedValue;

    @Prop({
        default: false
    }) isFinished: boolean;

    @Prop() nextChallenge: any;
    @Prop() backHome: any;

    created(){
    }

    get timerValue() {
        var totalTimeInSeconds = Math.round(this.totalMilliseconds / 1000);
        var totalMinutes = Math.floor(totalTimeInSeconds / 60);
        var seconds = totalTimeInSeconds % 60;
        var hours = Math.floor(totalMinutes / 60);
        var minutes = totalMinutes % 60;

        var finalHours = this.returnData(hours);
        var finalMinutes = this.returnData(minutes);
        var finalSeconds = this.returnData(seconds);

        return finalHours + " : " + finalMinutes + " : " + finalSeconds;
    }

    get tooltipText() {
        if(!this.tooltipLocalized)
            return "";

        return this.$localizationService.getTextFromValues(this.tooltipLocalized.values);
    }

    returnData(input) {
        return input >= 10 ? input : '0' + input;
    }

    goToRanking() {
        this.$opModal.closeLast();
        this.$router.replace('/ranking');
    }

}
