
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
    components: {
    }
})
export default class InfoModal extends Vue {

    @Prop({
        default: ""
    }) text: string

    created(){
    }

}
