import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/close.svg'


const _withScopeId = n => (_pushScopeId("data-v-b4dac210"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal" }
const _hoisted_2 = { class: "modalContent" }
const _hoisted_3 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _imports_0,
        class: "closeImage",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_localized_text, {
          type: "p",
          localizedKey: 'app_' + _ctx.text,
          text: _ctx.text,
          class: "text mt-4 mb-0"
        }, null, 8, ["localizedKey", "text"])
      ])
    ])
  ]))
}