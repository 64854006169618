
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
    components: {
    }
})
export default class WrongAnswerModal extends Vue {

    @Prop() totalMilliseconds: number;

    @Prop() retryNowCallback: any;
    @Prop() retryLaterCallback: any;

    created(){
    }

    get timerValue() {
        var totalTimeInSeconds = Math.round(this.totalMilliseconds / 1000);
        var totalMinutes = Math.floor(totalTimeInSeconds / 60);
        var seconds = totalTimeInSeconds % 60;
        var hours = Math.floor(totalMinutes / 60);
        var minutes = totalMinutes % 60;

        var finalHours = this.returnData(hours);
        var finalMinutes = this.returnData(minutes);
        var finalSeconds = this.returnData(seconds);

        return finalHours + " : " + finalMinutes + " : " + finalSeconds;
    }

    returnData(input) {
        return input >= 10 ? input : '0' + input;
    }

}
