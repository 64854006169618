
import { Options, Vue } from 'vue-class-component';
import { GameClient } from '@/services/Services';
import { StorageServices } from '@/services/StorageServices';
import InfoButton from '../components/infoButton.vue';
import StillPlayingModal from '../modals/stillPlayingModal.vue';
import WrongAnswerModal from '../modals/wrongAnswerModal.vue';
import CorrectAnswerModal from '../modals/correctAnswerModal.vue';
import InfoModal from '../modals/infoModal.vue';
import * as OM from '@/Model';

@Options({
    components: {
        InfoButton
    }
})
export default class ChallengeGame extends Vue {

    gaveAnswersCount: number = 0;
    questionsCount: number = StorageServices.getQuestionsCount();
    loaded: boolean = false;

    model: OM.GameQuestionVM = new OM.GameQuestionVM();
    totalTimeInSeconds: number = 0;
    timerInterval: any;

    inactivityInterval: any;
    
    created() {
        this.init(true);
    }

    init(getChallenge: boolean) {
        GameClient.getCurrentExtraction()
        .then(x => {
            this.gaveAnswersCount = x.userAnswers.length + 1;
            if(this.gaveAnswersCount > this.questionsCount)
                this.gaveAnswersCount = this.questionsCount;
            
            if(getChallenge) {
                GameClient.getNextQuestion()
                .then(x => {
                    this.model = x;
                    this.initIntervals();
                })
                .catch(y => {
                    this.$router.replace('/profile');
                })
            }
            else 
                this.initIntervals();
            })
    }

    initIntervals() {
        this.setTimerInterval();
        this.setInactivityInterval();

        this.loaded = true;
    }

    setTimerInterval() {
        this.timerInterval = setInterval(() => {
            this.totalTimeInSeconds += 1;
        }, 1000);
    }
    setInactivityInterval() {
        this.inactivityInterval = setInterval(() => {
            clearInterval(this.timerInterval);
            clearInterval(this.inactivityInterval);
            this.$opModal.show(StillPlayingModal, {
                yesCallback: () => {
                    this.$opModal.closeAll();
                    this.setTimerInterval();
                    this.setInactivityInterval();
                },
                noCallback: () => {
                    this.$opModal.closeAll();
                    this.$router.replace("/profile");
                }
            })
        }, 30000);
    }

    get timerValue() {
        var totalMinutes = Math.floor(this.totalTimeInSeconds / 60);
        var seconds = this.totalTimeInSeconds % 60;
        var hours = Math.floor(totalMinutes / 60);
        var minutes = totalMinutes % 60;

        var finalHours = this.returnData(hours);
        var finalMinutes = this.returnData(minutes);
        var finalSeconds = this.returnData(seconds);

        return finalHours + " : " + finalMinutes + " : " + finalSeconds;
    }

    returnData(input) {
        return input >= 10 ? input : '0' + input;
    }

    answerToQuestion(answerIdentifier: string) {
        clearInterval(this.timerInterval);
        clearInterval(this.inactivityInterval);

        var saveAnswerToQuestionVM = new OM.SaveAnswerToQuestionTimeVM();
        saveAnswerToQuestionVM.questionIdentifier = this.model.questionIdentifier;
        saveAnswerToQuestionVM.answerIdentifier = answerIdentifier;
        saveAnswerToQuestionVM.time = this.totalTimeInSeconds;

        GameClient.checkCorrectQuestion(saveAnswerToQuestionVM)
        .then(x => {
            if(x) {
                GameClient.saveAnswerToQuestion(saveAnswerToQuestionVM)
                .then(x => {
                    this.$opModal.show(CorrectAnswerModal, {
                        totalMilliseconds: (this.totalTimeInSeconds * 1000),
                        tooltipLocalized: this.model.correctToolTip,
                        isFinished: this.gaveAnswersCount >= this.questionsCount,
                        nextChallenge: () => {
                            this.$opModal.closeAll();
                            this.init(true);
                        },
                        backHome: () => {
                            this.$opModal.closeAll();
                            this.$router.replace("/profile");
                        }
                    })
                })
            }
            else {
                this.$opModal.show(WrongAnswerModal, {
                    totalMilliseconds: (this.totalTimeInSeconds * 1000),
                    retryNowCallback: () => {
                        this.$opModal.closeAll();
                        this.init(false);
                    },
                    retryLaterCallback: () => {
                        this.$opModal.closeAll();
                        this.$router.replace("/profile");
                    }
                })
            }
        })
    }

    openInfoModal() {
        this.$opModal.show(InfoModal, {
            text: "Le domande vertono sulla conoscenza di Merlata Bloom Milano e degli artisti che si esibiranno agli IDays 2024. Per ogni risposta corretta ottieni 5 punti e il tempo sarà determinante solo in condizione di parità di punteggio con altri concorrenti."
        })
    }

    unmounted() {
        clearInterval(this.timerInterval);
        clearInterval(this.inactivityInterval);
    }

}
