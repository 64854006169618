
import { Options, Vue } from 'vue-class-component';
import RulesInfoModal from '../modals/rulesInfoModal.vue';

@Options({
})
export default class InfoButton extends Vue {

    openInfoModal() {
        this.$opModal.show(RulesInfoModal, {
            
        })
    }
    
}
