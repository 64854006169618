import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/info.svg'
import _imports_1 from '@/assets/img/close.svg'


const _withScopeId = n => (_pushScopeId("data-v-0f7f218e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "pageContainer bg_redBlackWhite challenge"
}
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "sideContainer" }
const _hoisted_4 = { class: "mb-0" }
const _hoisted_5 = { class: "sideContainer text-end" }
const _hoisted_6 = { class: "bg_grey text-center py-4 mt-3 content" }
const _hoisted_7 = {
  key: 0,
  class: "position-relative mb-5"
}
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "timer insidePicture" }
const _hoisted_10 = {
  key: 1,
  class: "timer mb-4 pb-1"
}
const _hoisted_11 = { class: "mb-4" }
const _hoisted_12 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              src: _imports_0,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openInfoModal()))
            })
          ]),
          _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.gaveAnswersCount) + "/" + _toDisplayString(_ctx.questionsCount), 1),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("img", {
              src: _imports_1,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.replace('/profile')))
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.model.questionImageUrl)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("img", {
                  src: _ctx.model.questionImageUrl,
                  class: "w-100"
                }, null, 8, _hoisted_8),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "app_Timer",
                    text: "Timer",
                    class: "text"
                  }),
                  _createElementVNode("h3", null, _toDisplayString(_ctx.timerValue), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.model.questionImageUrl)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createVNode(_component_localized_text, {
                  localizedKey: "app_Timer",
                  text: "Timer",
                  class: "text"
                }),
                _createElementVNode("h3", null, _toDisplayString(_ctx.timerValue), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("h3", _hoisted_11, _toDisplayString(_ctx.$localizationService.getTextFromValues(_ctx.model.question.values)), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.answers, (answer, index) => {
            return (_openBlock(), _createElementBlock("button", {
              class: "buttonAsInput w-100 mb-3 px-1",
              key: index,
              onClick: ($event: any) => (_ctx.answerToQuestion(answer.answerIdentifier))
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$localizationService.getTextFromValues(answer.answer.values)), 1)
            ], 8, _hoisted_12))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}